(window as any).global = window;

import { ZEF } from '@shared/models/zef.model';

import 'zone.js';

declare global {
  interface Window {
    ZEF?: ZEF;
    global: Window;
    HubSpotConversations: any;
    hsConversationsSettings: any;
    hsConversationsOnReady: any;
  }
}
